<template>
    <div>
        <headTitle :isMain="3"/>
        <img class="mobile-example-headImg" src="../../assets/image/mobile/02-2DPM数字化工单.png"/>
        <div class="mobile-head-explain">
            <div class="mobile-head-explain-title">DPM数字化工单</div>
            <div class="mobile-head-explain-content">实现工单科学管理，提升生产稳定性</div>
        </div>
        <div class="mobile-head-catalogue" >
            <div :class="{'title1':true,'ischecked':checkIndex==1} " @click="(checkIndex=1)"><span>业务痛点</span></div>
            <div :class="{'title1':true,'ischecked':checkIndex==2} " @click="(checkIndex=2)"><span>解决方案</span></div>
            <div :class="{'title1':true,'ischecked':checkIndex==3} " @click="(checkIndex=3)"><span>方案收益</span></div>
        </div>
        <div v-if="(checkIndex==1)" class="mobile-content-box1">
            <div class="ware-title" style="margin-top:6.2rem">设备信息离散</div>
            <div class="ware-content1" style="margin-top:1rem">设备资料都以纸质形式或公共盘的形式保存，现场维护工作时，无法及时查看资料；</div>
            <div class="ware-title" style="margin-top:4.8rem">问题反馈效率慢</div>
            <div class="ware-content1" style="margin-top:1rem">问题反馈速率慢，导致人机、物料等待延时，造成损失；</div>
            <div class="ware-title" style="margin-top:4.8rem">工作完成质量不易追踪</div>
            <div class="ware-content1" style="margin-top:1rem">任务完成质量不易把控，设备运行容易出现故障，影响生产效率；</div>
            <div class="ware-title" style="margin-top:4.8rem">流程自动化程度低</div>
            <div class="ware-content1" style="margin-top:1rem">线下交叉沟通时间长，信息传递不及时；</div>
            <div class="ware-title" style="margin-top:4.8rem">维护经验知识沉淀难</div>
            <div class="ware-content1" style="margin-top:1rem">维修记录反馈均为纸质，无法形成维修经验知识库，不能及时生成各项指标及管理报表；</div>
            <div class="ware-title" style="margin-top:4.8rem">管理信息断层</div>
            <div class="ware-content1" style="margin-top:1rem">管理层无法直接了解到各个工厂的实际生产情况，工厂间绩效缺少比较与竞争；</div>
            <div class="ware-title" style="margin-top:4.8rem">数据分析效率低</div>
            <div class="ware-content1" style="margin-top:1rem;margin-bottom: 5rem;">各项数据整合分析困难，维护情况和设备停机时间无法建立联系。</div>
        </div>
        <div v-else-if="(checkIndex==2)" class="mobile-content-box1">
            <img class="ware-img" style="margin-bottom:5rem" src="../../assets/image/example/02-3解决方案.svg"/>
        </div>
        <div v-else-if="(checkIndex==3)" class="mobile-content-box1">
            <img class="ware-img2" style="margin-bottom:5rem" src="../../assets/image/example/02-3方案收益.svg"/>
        </div>
        <mobileFoot/>
    </div>
</template>

<script>
import headTitle from '../../components/mobile/head-title.vue'
import mobileFoot from '../../components/mobile/mobile-foot.vue'
import mobileCarousel from '../../components/mobile/mobile-carousel.vue'
import Swiper from "swiper";
export default {
    name: 'BizfocusIndexMobileIndex',
    components:{headTitle,mobileCarousel,mobileFoot},
    data() {
        return {
            checkIndex: 1,
            showDescription:false,
            box2ImageList:[
                {
                    index:0,
                    urlImg:require("../../assets/image/index/01-15GF整合数据分析系统.png"),
                    title:'5GF整合数据分析系统',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-5GF'
                },
                {
                    index:1,
                    urlImg:require("../../assets/image/index/01-1数字化产线.png"),
                    title:'数字化产线', //右转是0
                    content:'打造透明、高效、稳定的数字化生产管理平台',
                    route:'/selected-line'
                },
                {
                    index:2,
                    urlImg:require("../../assets/image/index/01-1市场渗透率分析.png"),
                    title:'市场渗透率分析',
                    content:'全球某化妆品巨头',
                    route:'/selected-marykay'
                },
                {
                    index:3,
                    urlImg:require("../../assets/image/index/01-1智能仓储.png"),
                    title:'智能仓储',
                    content:'布局原料赋码，助力品质生产',
                    route:'/selected-warehousing'
                },
                {
                    index:4,
                    urlImg:require("../../assets/image/index/01-1Dashboard数据实施.png"),
                    title:'Dashboard数据实施',
                    content:'全球某知名乳制品公司',
                    route:'/selected-dashboard'
                },
                {
                    index:5,
                    urlImg:require("../../assets/image/index/01-1商情诊断管理分析.png"),
                    title:'商情诊断管理分析',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-business'
                },
                {
                    index:6,
                    urlImg:require("../../assets/image/index/01-1iDAS整合数据分析系统.png"),
                    title:'iDAS整合数据分析系统', //左转是0
                    content:'韩国某知名化妆品集团',
                    route:'/selected-iDAS'
                },

            ],
        };
    },

    mounted() {
    },

    methods: {
        jumpMobileRoute(route){
            this.$router.push(route)
            console.log(route)
        }
    },
};
</script>

<style lang="less" scoped>
.description{
    display: flex;
    width: 66.5rem;
    margin: auto;
    margin-top: 6rem;
    .title1{
        font-size: 2.8rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #11A84F;
        line-height: 4.2rem;
    }
    .title2{
        font-size: 2.8rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #11A84F;
        line-height: 4.2rem;
        margin-left: 70%;
    }
}
.description-box{
    max-width: 66.5rem;
    margin: auto;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 5rem;
}
.mobile-head-catalogue{
    width: 100%;
    height: 9rem;
    display: flex;
    background: #F8F8F8;
    .title1{
        width: 20rem;
        height: 9rem;
        display: flex;
        text-align: center;
        align-items: center;
        span{
            font-size: 2.8rem;
            font-family: 'CN_Medium';
            font-weight: 500;
            color: #4F4F4F;
            line-height: 2rem;
            margin: auto;
        }
    }
    .ischecked{
        background: #EDEDED;
        border-bottom: 2px solid #11A84F;
    }
}
.mobile-content-box1{
    width: 66rem;
    margin: auto;
    margin-top: 3rem;
}
.ware-content1{
    max-width: 66rem;
    // height: 10.6rem;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 4rem;
}
.ware-title{
    font-size: 2.8rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 4.2rem;
}
.ware-box1{
    width: 66.8rem;
    height: 10rem;
    border: 1px solid #CCCCCC;
    margin: auto;
    margin-top: 1.3rem;
    display: flex;
    .title1{
        width: 58.8rem;
        height: 4rem;
        font-size: 2.8rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #666666;
        line-height: 4rem; 
        margin: auto; 
        align-items: center;
    }
}
.ware-img{
    width: 100%;
    height: 40.7rem;
    margin: auto;
}
.ware-img2{
    width: 100%;
    height: 63.3rem;
    margin: auto;
}
</style>